<template>
    <div class="map-view">
        <Filter @filtered="filterAll" />
        <Panel :class="{ 'show': panelShow }" :events="events" @hidePanel="hidePanel" />
        <GoogleMap api-key="AIzaSyBKds2uWBtwsGgHwYQg2rh17S0oIu1jpZs" class="map" style="width: 100%;" :styles="mapStyle"
            :center="center" :zoom="zoom" :mapTypeControl="false">
            <MarkerCluster v-if="enable_clustering.value == 1">
                <Marker v-for="location in locations" :key="location.id"
                    :options="{ position: { lat: location.latitude, lng: location.longitude } }">
                    <InfoWindow @closeclick="hidePanel()" :ref="(el) => (infoWindows[location.id] = el)">
                        <div class="content">
                            <h4 id="firstHeading" class="firstHeading m-0">{{ location.name }}</h4>
                            <div id="bodyContent">
                                <p><b>{{ location.case_count }} {{ $t('map.events') }}</b> {{ $t('map.recorded') }}
                                </p>
                                <button class="btn-small">
                                    <router-link :to="'../cases/' + location.id">{{ $t('map.details')
                                        }}</router-link>
                                </button>
                            </div>
                        </div>
                    </InfoWindow>
                </Marker>
            </MarkerCluster>
            <div v-else>
                <Marker v-for="location in locations" :key="location.id"
                    :options="{ position: { lat: location.latitude, lng: location.longitude } }">
                    <InfoWindow @closeclick="hidePanel()" :ref="(el) => (infoWindows[location.id] = el)">
                        <div class="content">
                            <h4 id="firstHeading" class="firstHeading m-0">{{ location.name }}</h4>
                            <div id="bodyContent">
                                <p><b>{{ location.case_count }} {{ $t('map.events') }}</b> {{ $t('map.recorded') }}
                                </p>
                                <button class="btn-small">
                                    <router-link :to="'../cases/' + location.id">{{ $t('map.details')
                                        }}</router-link>
                                </button>
                            </div>
                        </div>
                    </InfoWindow>
                </Marker>
            </div>
            <Polygon :options="bermudaTriangle" />
        </GoogleMap>
    </div>
</template>

<script>

import axios from 'axios'
import { defineComponent } from "vue";
import Filter from "./Filter.vue";
import Panel from "./Panel.vue";
import { GoogleMap, MarkerCluster, Marker, InfoWindow, Polygon } from "vue3-google-map";
import moment from 'moment';
import triangleCoords from "../assets/city.js";
const base_url = process.env.VUE_APP_BASE_URL;


export default defineComponent({
    components: {
        Panel,
        Filter,
        GoogleMap,
        MarkerCluster,
        Marker,
        InfoWindow,
        Polygon,
    },
    data() {
        return {
            panelShow: false,
            openMarker: null,
            events: [],
            locations: [],
            name: "",
            filteredGenders: [],
            filteredStates: [],
            filteredCountries: [],
            startDate: "",
            endDate: "",
            startAge: "",
            endAge: "",
            infoWindows: [],
            triangleCoords,
            bermudaTriangle: "",
            zoom: 6,
            center: { lat: 27.156486, lng: 30.491588 },
            enable_clustering: 0
        }
    },
    beforeMount() {
        this.get_enable_clustering();
    },
    methods: {
        showPanel() {
            this.panelShow = true
        },
        hidePanel() {
            this.panelShow = false
        },
        closeInfoWindows(except) {
            this.infoWindows.forEach((ref, index) => {
                if (index !== except) ref.infoWindow.close();
            });
        },

        // Render cases to side panel
        async fetchCases(stateID) {
            let params = new URLSearchParams();
            // params.append('filter[state_id]', stateID);
            params.append('filter[name]', this.name);
            params.append('filter[gender_id]', this.filteredGenders);
            params.append('filter[state_id]', this.filteredStates);
            params.append('filter[nationality_id]', this.filteredCountries);
            params.append('filter[disappearance_date_from]', this.startDate);
            params.append('filter[disappearance_date_to]', this.endDate);
            params.append('filter[age_from]', this.startAge);
            params.append('filter[age_to]', this.endAge);
            const res = await fetch(base_url + '/cases/get_cases?' + params)
            const events = await res.json();
            return events.data
        },
        async renderPanel(stateID) {
            this.closeInfoWindows(stateID)
            this.events = await this.fetchCases(stateID)
        },

        // Render filtered locations
        async fetchLocations() {
            // this.closeInfoWindows(null);
            this.infoWindows = [];
            let params = new URLSearchParams();
            params.append('filter[name]', this.name);
            params.append('filter[gender_id]', this.filteredGenders);
            params.append('filter[state_id]', this.filteredStates);
            params.append('filter[nationality_id]', this.filteredCountries);
            params.append('filter[disappearance_date_from]', this.startDate);
            params.append('filter[disappearance_date_to]', this.endDate);
            params.append('filter[age_from]', this.startAge);
            params.append('filter[age_to]', this.endAge);

            const res = await fetch(base_url + '/cases/get_summary?' + params)
            const locations = await res.json()
            return locations.data
        },


        async filterAll(openPanel) {
            if (this.$store.state.date == null) this.$store.state.date = [];
            this.name = this.$store.state.name;
            this.filteredGenders = this.$store.state.selectedGenders;
            this.filteredStates = this.$store.state.selectedStates;
            this.filteredCountries = this.$store.state.selectedCountries;
            this.startDate = this.$store.state.date[0] ? moment(this.$store.state.date[0]).format("YYYY-MM-DD") : '';
            this.endDate = this.$store.state.date[1] ? moment(this.$store.state.date[1]).format("YYYY-MM-DD") : '';
            this.startAge = this.$store.state.selectedAges[0];
            this.endAge = this.$store.state.selectedAges[1];
            this.locations = await this.fetchLocations();
            this.events = await this.fetchCases();

            let obj = [];
            const coordinates = [];

            for (let index = 0; index < this.filteredStates.length; index++) {
                const element = this.filteredStates[index];
                obj[index] = triangleCoords.find((o, i) => {
                    if (o.city === element) {
                        this.center = o.center;
                        this.zoom = 8;
                        return o.coordinates;
                    }
                });
            }
            var nameArray = obj.map(function (el) { return el.coordinates; });

            nameArray.forEach(function (item, i) {
                coordinates[i] = buildCoordinatesArrayFromString(item);
            });

            if (this.filteredStates.length > 1) {
                this.center = { lat: 27.156486, lng: 30.491588 };
                this.zoom = 6;
            }

            this.bermudaTriangle = {
                paths: coordinates,
                strokeColor: "#1ebb69",
                strokeOpacity: 0.5,
                strokeWeight: 2,
                fillColor: "#1ebb69",
                fillOpacity: .7,
            };
            openPanel ? this.showPanel() : this.hidePanel();
        },

        get_enable_clustering() {
            axios
                .get(base_url + "/get_setting/enable_clustering")
                .then((response) => {
                    this.enable_clustering = response.data.data;

                })
            // .catch((error) => {
            //     let res = error.response;
            //     toaster.error(res.data.message);
            // });
        },
    },

    async created() {
        this.locations = await this.fetchLocations();
    },

    setup() {
        //const center = { lat: 27.156486, lng: 30.491588 };
        const mapStyle = [
            {
                "featureType": "all",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "weight": "2.00"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#9c9c9c"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#f2f2f2"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "landscape.man_made",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 45
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#7b7b7b"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#46bcec"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#c8d7d4"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#070707"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            }
        ];


        return { mapStyle };
    },

    mounted() {
        const coordinates = [];
        // const triangleCoords = [];
        var nameArray = triangleCoords.map(function (el) { return el.coordinates; });
        nameArray.forEach(function (item, i) {
            coordinates[i] = buildCoordinatesArrayFromString(item);
        });

        this.bermudaTriangle = {
            paths: coordinates,
            strokeColor: "#b41412",
            strokeOpacity: 0.3,
            strokeWeight: 1,
            fillColor: "#b41412",
            fillOpacity: 0,

        };
    }
});

function buildCoordinatesArrayFromString(MultiGeometryCoordinates) {
    var finalData = [];
    var grouped = MultiGeometryCoordinates.split(" ");

    grouped.forEach(function (item, i) {
        let a = item.trim().split(',');

        finalData.push({
            lng: parseFloat(a[0]),
            lat: parseFloat(a[1])
        });
    });

    return finalData;
}
</script>

<style scoped>
.map-view {
    display: flex;
}

.map {
    margin-right: 0;
    transition: 0.3s;
    width: 100%;
    height: calc(100svh - 80px);
}

.panel.show+.map {
    margin-right: 300px;
}

.rtl .panel.show+.map {
    margin-right: 0;
    margin-left: 300px;
}

.filter:has(+.panel.show) {
    margin-right: 300px;
}

.rtl .filter:has(+.panel.show) {
    margin-right: 0;
    margin-left: 300px;
}

@media(max-width: 992px) {
    .map {
        height: calc(100svh - 120px);
    }
}

@media(max-width: 768px) {
    .panel.show+.map {
        margin-right: 250px;
    }

    .rtl .panel.show+.map {
        margin-right: 0;
        margin-left: 250px;
    }

    .filter:has(+.panel.show) {
        width: calc(100% - 80px - 250px);
        margin-right: 250px;
    }

    .rtl .filter:has(+.panel.show) {
        margin-right: 0;
        margin-left: 250px;
    }
}

@media (max-width: 567px) {
    .filter:has(+.panel.show) {
        width: calc(100% - 80px);
        margin-right: 0;
    }

    .rtl .filter:has(+.panel.show) {
        margin-right: 0;
        margin-left: 0;
    }
}
</style>
