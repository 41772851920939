import { createStore } from 'vuex'

export const store = createStore({
    state() {
        return {
            name: "",
            selectedStates: [],
            selectedGenders: [],
            selectedCountries: [],
            selectedAges: [],
            date: [],
            rtl: localStorage.getItem('lang') == 'ar'
        }
    },
}) 