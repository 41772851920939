<template>
    <div class="page-content" :class="{'with-filter':showFilter, 'with-case':showAddCase}">
        <GMap />
    </div>
</template>

<script>
    import GMap from "../components/GMap.vue";

    export default{
        name: "Home",
        components: {
            GMap,
        },
        props:{
            showAddCase: Boolean,
        }
    }
</script>

<style scoped>
    .btn.panel-toggle-btn{
        position: absolute;
        top: 15px;
        left: 15px;
        font-size: 0.75rem;
        padding: 0.5rem 1.25rem;
        border-radius: 5rem;
        z-index: 100;
        box-shadow: 0 0 20px -10px #222;
    }
    .page-content{
        padding: 0;
    }

</style>