<template>
    <div class="panel">
        <button class="close-panel" @click="hidePanel"><font-awesome-icon
                icon="fa-solid fa-times"></font-awesome-icon></button>
        <router-link :to="'../cases/'" class="view-all-btn">{{ $t("view-all") }}</router-link>
        <div class="event" v-for="event in events" :key="event.id">
            <router-link :to="'/case/' + event.id">
                <h5>{{ event.name }}</h5>
                <small>{{ event.disappearance_date }}</small>
                <small>{{ event.city.name }}</small>
                <b>{{ event.status }}</b>
            </router-link>
            <hr>
        </div>
    </div>
</template>

<script>

export default {
    name: "Panel",
    props: {
        events: Array,
        panelShow: false
    },
    methods: {
        hidePanel() {
            this.$emit('hidePanel')
        }
    }
}
</script>

<style scoped>
.panel {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-color: white;
    padding: 1rem 0rem;
    width: 0px;
    transition: 0.3s;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 100;
}

.rtl .panel {
    right: unset;
    left: 0;
}

.rtl .panel.show {
    background-color: white;
    padding: 1rem;
    width: 300px;
    box-shadow: 0 0 16px -10px var(--darkGrey);

}

.event a {
    position: relative;
    top: 0;
    display: block;
    padding: 1rem;
    box-shadow: 0 0 15px -5px #ddd;
    transition: 0.3s;
}

.event a:hover {
    top: -5px;
    display: block;
    padding: 1rem;
    box-shadow: 0 2px 15px -5px #ddd;
}

h5 {
    font-weight: bold;
    color: var(--mainColorDarker);
    margin: 0;
}

b {
    display: flex;
    align-items: center;
    gap: 0 0.5rem;
    color: #888;
}

small {
    display: block;
    color: #bbb;
    font-size: 0.75rem;
}

.view-all-btn {
    display: block;
    text-align: center;
    border-radius: 5px;
    margin: 0 0 15px 0;
    padding: 10px;
    background-color: #b41412;
    outline: none;
    color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.close-panel {
    border: none;
    background: none;
    color: #888;
    margin-bottom: 15px;
    font-size: 20px;
}

@media(max-width:768px) {
    .rtl .panel.show {
        width: 250px;
    }
}
</style>