<template>
    <div class="filter responsive_filter">
        <form action="" class="large_screen">
            <div class="accordion" id="filterAccordion">
                <div class="card">
                    <input type="text" name="name" v-model="name" placeholder="الإسم" @keyup.enter="filter">
                </div>
                <div class="card">
                    <div id="headingGender">
                        <button class="accordion-btn collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseGender" aria-expanded="false" aria-controls="collapseGender">
                            {{ $t("filter.gender") }}
                            <span v-if="selectedGenders.length > 0" class="badge bg-danger">{{ selectedGenders.length
                                }}</span>
                        </button>
                    </div>
                    <div id="collapseGender" class="collapse" aria-labelledby="headingGender"
                        data-bs-parent="#filterAccordion">
                        <div class="card-body">
                            <ul class="genders">
                                <li v-for="gender in genders" :key="gender.id" :id="gender.id" class="gender"
                                    :class="{ 'checked': selectedGenders.includes(gender.id) }"
                                    @click="checkGender(gender.id)"><span>{{ gender.title }}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div id="headingNationality">
                        <button class="accordion-btn collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseNationality" aria-expanded="false"
                            aria-controls="collapseNationality">
                            {{ $t("filter.nationality") }}
                            <span v-if="selectedCountries.length > 0" class="badge bg-danger">{{
                                selectedCountries.length }}</span>
                        </button>
                    </div>
                    <div id="collapseNationality" class="collapse" aria-labelledby="headingNationality"
                        data-bs-parent="#filterAccordion">
                        <div class="card-body">
                            <ul class="countries">
                                <li v-for="country in countries " :key="country.id" :id="country.id" class="country"
                                    :class="{ 'checked': selectedCountries.includes(country.id) }"
                                    @click="checkCountry(country.id)"><span>{{ country.name }}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div id="headingCountry">
                        <button class="accordion-btn collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseCountry" aria-expanded="false" aria-controls="collapseCountry">
                            {{ $t("filter.state") }}
                            <span v-if="selectedStates.length > 0" class="badge bg-danger">{{ selectedStates.length
                                }}</span>
                        </button>
                    </div>
                    <div id="collapseCountry" class="collapse" aria-labelledby="headingCountry"
                        data-bs-parent="#filterAccordion">
                        <div class="card-body">
                            <ul class="countries">
                                <li v-for="state in states" :key="state.id" :id="state.id" class="state"
                                    :class="{ 'checked': selectedStates.includes(state.id) }"
                                    @click="checkState(state.id)">
                                    <span>{{ state.name }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div id="headingAge">
                        <button class="accordion-btn collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseAge" aria-expanded="false" aria-controls="collapseAge">
                            {{ $t("filter.age") }}
                        </button>
                    </div>
                    <div id="collapseAge" class="collapse" aria-labelledby="headingAge"
                        data-bs-parent="#filterAccordion">
                        <div class="card-body slider-body">
                            <Slider v-model="slider.value" v-bind="slider"></Slider>
                        </div>
                    </div>
                </div>
                <VueDatePicker v-model="date" :locale="($i18n.locale == 'ar') ? 'ar' : 'en'"
                    :placeholder="$t('filter.date')" auto-apply range :preset-ranges="presetRanges" week-start="0" dark
                    :enable-time-picker="false" hide-input-icon input-class-name="date-picker"
                    menu-class-name="date-picker-menu" calendar-cell-class-name="date-picker-cell"
                    calendar-class-name="date-pocker-calendar" position="left" :multi-calendars="{ solo: true }">
                    <span @click="presetDateRange(range)">{{ $t('label') }}</span>
                </VueDatePicker>
            </div>
            <button @click="filter" type="button" class="accordion-btn justify-content-center mt-2">{{
                $t("filter.apply-filter")
            }}</button>
            <button @click="resetFilter" type="button" class="accordion-btn btn-danger justify-content-center mt-2">{{
                $t("filter.reset")
            }}</button>
        </form>

        <!-- responsive -->
        <form action="" class="small_screen">
            <div class="accordion accordion_small" id="small_filterAccordion">
                <div class="card">
                    <div id="headingGender_small">
                        <button class="accordion-btn" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseGender_small" aria-expanded="true"
                            aria-controls="collapseGender_small">
                            <!-- {{ $t("filter.gender") }} -->
                            Filter
                        </button>
                    </div>
                    <div id="collapseGender_small" class="collapse" aria-labelledby="headingGender_small"
                        data-bs-parent="#small_filterAccordion">
                        <div class="card-body">
                            <div class="accordion" id="filterAccordion1">
                                <div class="card">
                                    <input type="text" name="name" v-model="name" placeholder="الإسم">
                                </div>
                                <div class="card">
                                    <div id="headingGender_small">
                                        <button class="accordion-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseGender" aria-expanded="true"
                                            aria-controls="collapseGender">
                                            {{ $t("filter.gender") }}
                                        </button>
                                    </div>
                                    <div id="collapseGender" class="collapse" aria-labelledby="headingGender_small"
                                        data-bs-parent="#filterAccordion1">
                                        <div class="card-body">
                                            <ul class="genders">
                                                <li v-for="gender in genders" :key="gender.id" :id="gender.id"
                                                    class="gender"
                                                    :class="{ 'checked': selectedGenders.includes(gender.id) }"
                                                    @click="checkGender(gender.id)"><span>{{ gender.title }}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div id="headingNationality1">
                                        <button class="accordion-btn collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseNationality1" aria-expanded="false"
                                            aria-controls="collapseNationality1">
                                            {{ $t("filter.nationality") }}
                                        </button>
                                    </div>
                                    <div id="collapseNationality1" class="collapse"
                                        aria-labelledby="headingNationality1" data-bs-parent="#filterAccordion1">
                                        <div class="card-body">
                                            <ul class="countries">
                                                <li v-for="country in countries " :key="country.id" :id="country.id"
                                                    class="country"
                                                    :class="{ 'checked': selectedCountries.includes(country.id) }"
                                                    @click="checkCountry(country.id)"><span>{{ country.name
                                                        }}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div id="headingCountry1">
                                        <button class="accordion-btn collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseCountry1" aria-expanded="false"
                                            aria-controls="collapseCountry1">
                                            {{ $t("filter.state") }}
                                        </button>
                                    </div>
                                    <div id="collapseCountry1" class="collapse" aria-labelledby="headingCountry1"
                                        data-bs-parent="#filterAccordion1">
                                        <div class="card-body">
                                            <ul class="countries">
                                                <li v-for="state in states" :key="state.id" :id="state.id" class="state"
                                                    :class="{ 'checked': selectedStates.includes(state.id) }"
                                                    @click="checkState(state.id)">
                                                    <span>{{ state.name }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div id="headingAge1">
                                        <button class="accordion-btn collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseAge1" aria-expanded="false"
                                            aria-controls="collapseAge1">
                                            {{ $t("filter.age") }}
                                        </button>
                                    </div>
                                    <div id="collapseAge1" class="collapse" aria-labelledby="headingAge1"
                                        data-bs-parent="#filterAccordion1">
                                        <div class="card-body slider-body">
                                            <Slider v-model="slider.value" v-bind="slider"></Slider>
                                        </div>
                                    </div>
                                </div>
                                <VueDatePicker v-model="date" :locale="($i18n.locale == 'ar') ? 'ar' : 'en'"
                                    :placeholder="$t('filter.date')" auto-apply range week-start="0" dark
                                    :enable-time-picker="false" hide-input-icon input-class-name="date-picker"
                                    menu-class-name="date-picker-menu" calendar-cell-class-name="date-picker-cell"
                                    calendar-class-name="date-pocker-calendar" position="center"
                                    :multi-calendars="{ solo: true }">
                                    <span @click="presetDateRange(range)">{{ $t('label') }}</span>
                                </VueDatePicker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button @click="filter" type="button" class="accordion-btn btn_small justify-content-center mt-2">{{
                $t("filter.apply")
            }}</button>
            <button @click="resetFilter" type="button" class="accordion-btn btn-danger justify-content-center mt-2">{{
                $t("filter.reset")
            }}</button>
        </form>

    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import Slider from '@vueform/slider';
import { endOfMonth, endOfYear, startOfMonth, startOfYear, endOfWeek, startOfWeek, subMonths } from 'date-fns';
import '@vuepic/vue-datepicker/dist/main.css';
import { mapState } from 'vuex'

const base_url = process.env.VUE_APP_BASE_URL;

export default {
    name: "Filter",
    components: {
        VueDatePicker,
        onMounted,
        Slider,
    },
    setup() {
        // Date picker
        const date = ref();
        const presetRanges = ref([
            { label: 'Today', range: [new Date(), new Date()] },
            { label: 'This Week', range: [startOfWeek(new Date()), endOfWeek(new Date())] },
            { label: 'This Month', range: [startOfMonth(new Date()), endOfMonth(new Date())] },
            { label: 'Last Month', range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))], },
            { label: 'This Year', range: [startOfYear(new Date()), endOfYear(new Date())] },
        ]);
        onMounted(() => {
            // const startDate = new Date();
            // const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
            // date.value = [startDate, endDate];
        })
        return { date, presetRanges }
    },
    data() {
        return {
            name: "",
            states: [],
            genders: [],
            countries: [],
            ages: [],
            slider: {
                value: [0, 0],
                merge: 5,
                direction: "ltr"
            },
            rtl: localStorage.getItem('lang') == 'ar'
        }
    },
    computed: {
        ...mapState([
            "name",
            "selectedStates",
            "selectedGenders",
            "selectedCountries",
            "selectedAges"
        ])
    },
    methods: {
        filterCheck(array, value) {
            if (array.includes(value)) {
                array = array.filter(function (filtered) {
                    return filtered !== value;
                });
            } else {
                array.push(value);
            };
            return array
        },
        checkGender(genderID) {
            this.$store.state.selectedGenders = this.filterCheck(this.$store.state.selectedGenders, genderID);
        },
        checkState(stateID) {
            this.$store.state.selectedStates = this.filterCheck(this.$store.state.selectedStates, stateID);
        },
        checkCountry(countryID) {
            this.$store.state.selectedCountries = this.filterCheck(this.$store.state.selectedCountries, countryID);
        },
        checkAge(ageID) {
            this.$store.state.selectedAges = this.filterCheck(this.$store.state.selectedAges, ageID);
        },

        // Fetching data from API
        async fetchGenders() {
            const res = await fetch(base_url + '/get_genders?lang_id=2')
            const genders = await res.json()
            return genders.data
        },
        async fetchStates() {
            const res = await fetch(base_url + '/get_states?lang_id=2&country_id=65')
            const states = await res.json()
            return states.data
        },
        async fetchCountries() {
            const res = await fetch(base_url + "/get_countries")
            const countries = await res.json()
            return countries.data
        },
        async fetchAges() {
            const res = await fetch(base_url + '/helpers/get_age_range?lang_id=2')
            const ages = await res.json()
            return ages
        },

        // Send data to Parent
        filter(openPanel = true) {
            this.$store.state.name = this.name;
            this.$store.state.date = this.date;
            this.$store.state.selectedAges = [
                (this.slider.min == this.slider.value[0] ? '' : this.slider.value[0]),
                (this.slider.max == this.slider.value[1] ? '' : this.slider.value[1])
            ];
            this.$emit('filtered', openPanel)
        },
        resetFilter() {
            this.$store.state.name = ""
            this.$store.state.selectedGenders = []
            this.$store.state.selectedStates = []
            this.$store.state.selectedCountries = []
            this.$store.state.date = []
            this.slider.value = [this.slider.min, this.slider.max]
            this.filter(false);
        }
    },
    async created() {
        this.genders = await this.fetchGenders()
        this.states = await this.fetchStates()
        this.countries = await this.fetchCountries()
        this.ages = await this.fetchAges().then(ages => {
            this.slider.value = [ages[0].min_age, ages[0].max_age]
            this.slider.min = ages[0].min_age
            this.slider.max = ages[0].max_age
        })
        this.slider.direction = this.rtl ? 'rtl' : 'ltr'
    }
}

</script>

<style src="@vueform/slider/themes/default.css"></style>
<style scoped>
.filter {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 0px;
    transition: 0.3s;
    z-index: 50;
    width: 260px;
    max-height: 100%;
}

.filter * {
    white-space: nowrap;
}

.filter .card-body {
    padding: 0;
}

.filter .card-body.slider-body {
    padding: 1.5rem;
}

.filter ul {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #23282b10;
    max-height: 200px;
    overflow-x: visible;
    overflow-y: auto;
}

.filter ul li {
    /* text-align: left; */
    border-bottom: 1px solid #ffffff0a;
    cursor: pointer;
}

.filter ul li span {
    display: block;
    padding: .75rem 1.5rem;
    transition: 0.3s;
    color: white;
}

.filter ul li.checked span {
    padding: .75rem 2rem;
    opacity: 1;
    color: #FFB22C;
}

.filter .accordion {
    border-radius: 0;
    box-shadow: 0 0 20px 0 #222;

}

.filter .accordion .card {
    border: none;
    border-radius: 0;
    background-color: #23282b;
    box-shadow: none;
    padding: 0;
}

.filter .accordion-btn,
.filter .accordion .card input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    color: var(--lightGrey);
    background-color: #292e30 !important;
    color: white !important;
    border-radius: 0;
    width: 100%;
    border: none;
    border-bottom: 1px solid #0003 !important;
}

.filter .accordion .card input::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.filter .accordion .card input:focus {
    outline: none;
}

.filter .accordion-btn.btn-danger {
    background-color: #b41412 !important;
    flex: 100% 0 0;
}

/*  responsive */
.small_screen {
    display: none;
}

@media(max-width:768px) {
    .filter {
        width: calc(100% - 80px);
    }

    .small_screen {
        display: flex;
        align-items: end;
        flex-wrap: wrap;
    }

    .small_screen .btn_small {
        max-width: 25%;
        min-width: 105px;
        background-color: #000 !important;
    }

    .small_screen .accordion_small {
        flex: 1;
        width: 75%;
    }

    .large_screen {
        display: none !important;
    }

    /* Filter styles for cases page */
    .filter-wrapper .filter {
        width: 100%;
    }

    .filter-wrapper .large_screen {
        display: block !important;
    }

    .filter-wrapper .small_screen {
        display: none !important;
    }
}
</style>